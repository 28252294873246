import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { get } from 'lodash';

import Layout from '../components/site-layout/site-layout';
import { BlogTagContext } from '../types/BlogTagContext';
import { Head } from '../components/site-layout/head';
import BlogList from '../components/blog-post/blog-list';

const Tags: React.FC<PageProps<GatsbyTypes.BlogTagPageQueryQuery, BlogTagContext>> = ({
  pageContext,
  data,
  location,
}) => {
  const { tag } = pageContext;
  const totalCount = get(data.posts, 'totalCount', 0);
  const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${tag}"`;

  return (
    <Layout location={location} image={data.headerImage?.childImageSharp?.gatsbyImageData}>
      <Head title={`Blog Tag '${tag}'`} />
      <BlogList title={tagHeader} posts={data.posts.edges.map((egde) => egde.node)} />
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query BlogTagPageQuery($tag: String) {
    headerImage: file(relativePath: { eq: "images/lp-blog.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 4000)
      }
    }
    posts: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
  }
`;
